<template>
<head>
  <title>会社概要</title>
</head>
<aheader />

<body>
  <div>
    <h1 class="title m-4">会社概要</h1>
    <section class="section">
      <div class="columns">
        <figure class="column is-3 is-offset-2">
          <p class="image">
            <img src="@/assets/oguraya_info.jpeg">
          </p>
        </figure>
        <div class="column is-5">
            <p class="title is-5 is-spaced">ごあいさつ</p>
            <p class="has-text-left">
              曽祖父、小倉庄太郎がこの地で店を構えて１００年が経ち、地域の人たちと共に歩んでまいりました。
              店の様子は当時と変わっておりますが、お客様に喜んでいただきたいという思いは創業当時と変わっておりません。
              今後も多くの方たちと幸せを共有できることを願っております。
              <br><br>
            </p>
            <p class="has-text-right">
              代表取締役社長   義平  稔
            </p>
        </div>

      </div>
    </section>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-6">
          <h3 class="title is-4 has-text-center">基本情報</h3>
          <table class="table is-fullwidth">
            <tbody>
              <tr>
                <td class="has-text-left">社名</td>
                <td class="has-text-left">株式会社おぐら屋</td>
              </tr>
              <tr>
                <td class="has-text-left">
                  所在地
                </td>
                <td class="has-text-left">
                  〒535-0013
                  <br>大阪府大阪市旭区森小路2丁目9-2
                </td>
              </tr>
              <tr>
                <td class="has-text-left">TEL</td>
                <td class="has-text-left">
                  06-6954-1515
                </td>
              </tr>
              <tr>
                <td class="has-text-left">
                  代表
                </td>
                <td class="has-text-left">
                  善平 稔
                </td>
              </tr>
              <tr>
                <td class="has-text-left">
                  創業
                </td>
                <td class="has-text-left">
                  大正3年3月15日
                </td>
              </tr>
              <tr>
                <td class="has-text-left">
                  事業内容
                </td>
                <td class="has-text-left">
                  寝装寝具販売 不動産仲介業
                  <br>ファイナンシャルプランニング
                  <br>活版・オフセット・オンデマンド各種印刷
                  <br>梅酒販売 割烹料理店
                </td>
              </tr>
              <tr>
                <td class="has-text-left">
                  本社
                </td>
                <td class="has-text-left">
                  大阪府旭区森小路2丁目9番2号
                </td>
              </tr>
              <tr>
                <td class="has-text-left">
                  関連会社
                </td>
                <td class="has-text-left">
                  京かい道 割烹庵 松くら
                  <br>咲梅shop
                </td>
              </tr>
            </tbody>
          </table>
          <!--
          <li>
            <dl align="left">
              <dt>社名</dt>
              <dd>株式会社おぐら屋</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>所在地</dt>
              <dd>〒535-0013
                <br>大阪府大阪市旭区森小路２丁目９－２（
                <a class="has-text-info-dark" href="https://goo.gl/maps/9mwV9KyqMdwoLkMWA">マップ</a>
                ）
              </dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>TEL</dt>
              <dd>06-6954-1515</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>代表</dt>
              <dd>義平 稔</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>創業</dt>
              <dd>大正3年3月15日</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>事業内容</dt>
              <dd>寝装寝具販売 不動産仲介業
                <br>ファイナンシャルプランニング
                <br>活版・オフセット・オンデマンド各種印刷
                <br>梅酒販売 割烹料理店
              </dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>本社</dt>
              <dd>大阪府旭区森小路2丁目9番2号
              </dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>関連会社</dt>
              <dd>京かい道 割烹庵 松くら
                <br>咲梅shop
              </dd>
            </dl>
          </li>
          -->
        </div>
      </div>
    </section>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-6">
          <h3 class="title is-4">沿革</h3>
           <table class="table is-fullwidth">
            <tbody>
              <tr>
                <td class="has-text-left">大正3年3月15日</td>
                <td class="has-text-left">森小路にて創業</td>
              </tr>
              <tr>
                <td class="has-text-left">大正5年</td>
                <td class="has-text-left">上辻に製綿工場建設</td>
              </tr>
              <tr>
                <td class="has-text-left">昭和25年</td>
                <td class="has-text-left">法人化 株式会社おぐら屋</td>
              </tr>
              <tr>
                <td class="has-text-left">昭和36年</td>
                <td class="has-text-left">日本橋店開店</td>
              </tr>
              <tr>
                <td class="has-text-left">昭和37年</td>
                <td class="has-text-left">別所町（現、大阪市鶴見区）に新工場建設
                  <br>自社ブランド綿「辨天綿」販売
                </td>
              </tr>
              <tr>
                <td class="has-text-left">昭和55年</td>
                <td class="has-text-left">資本金300万円に増資</td>
              </tr>
              <tr>
                <td class="has-text-left">平成2年</td>
                <td class="has-text-left">資本金1000万円に増資</td>
              </tr>
              <tr>
                <td class="has-text-left">平成19年</td>
                <td class="has-text-left">印刷事業開始</td>
              </tr>
              <tr>
                <td class="has-text-left">平成20年</td>
                <td class="has-text-left">食品事業開始</td>
              </tr>
              <tr>
                <td class="has-text-left">平成26年</td>
                <td class="has-text-left">創業100周年</td>
              </tr>
            </tbody>
          </table>
          <!--
          <li>
            <dl align="left">
              <dt>大正3年3月15日</dt>
              <dd>森小路にて創業</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>大正5年</dt>
              <dd>上辻に製綿工場建設</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>昭和25年</dt>
              <dd>法人化 株式会社おぐら屋</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>昭和36年</dt>
              <dd>日本橋店開店</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>昭和37年</dt>
              <dd>別所町（現、大阪市鶴見区）に新工場建設
                <br>自社ブランド綿「辨天綿」販売
              </dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>昭和55年</dt>
              <dd>資本金300万円に増資</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>平成2年</dt>
              <dd>資本金1000万円に増資</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>平成19年</dt>
              <dd>印刷事業開始</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>平成20年</dt>
              <dd>食品事業開始</dd>
            </dl>
          </li>
          <li>
            <dl align="left">
              <dt>平成26年</dt>
              <dd>創業100周年</dd>
            </dl>
          </li>
          -->
        </div>
      </div>
    </section>
    <section class="section">
      <div>
        <h3 class="title is-4">弊店に訪問される方へ</h3>
        <p>店舗までの案内について、詳しくは
          <a class="has-text-info-dark" @click="pushPage('access')">こちら</a>
          をご覧ください。
        </p>
      </div>
    </section>
  </div>
</body>
<afooter />
</template>
<script>
export default {
  mounted() {
    this.scrollTop()
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    pushPage(page) {
      this.$router.push(page)
    }
  }
}
</script>
<style scoped>
body {
  font-family: HGSSoeiPresenceEB;
}

li {
  list-style: none;
  border-bottom: solid 0.75px;
  padding: 2%;
}

dt {
  width: 50%
}

dl {
  display: flex;
}

td {
  width: 20%;
}

.button1 {
  align-items: center;
  margin: 10px;
  color: #FCFAF2;
  background-color: #C21732;
  border: 3px solid #C21732;
  border-radius: 5px;
}
</style>
